import { HREFLANG_LOCALE } from 'constants/prop-types'

import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useLocaleHrefLangLinks } from './use-locale-href-lang-links'

const HrefLangLinks = ({ currentUrl, hrefLangLinks, locales }) => {
  const localeHrefLangLinks = useLocaleHrefLangLinks({ currentUrl, locales })

  const links = useMemo(
    () => hrefLangLinks ?? localeHrefLangLinks,
    [hrefLangLinks, localeHrefLangLinks]
  )

  const rejectedHrefLangs = new Set([
    LANGUAGE_CODES.german,
    LANGUAGE_CODES.french,
  ])

  // Links without the rejected hreflangs
  const filteredLinks = links.filter(
    ({ hreflang }) => !rejectedHrefLangs.has(hreflang)
  )

  return filteredLinks.map(hrefLangLink => (
    <link
      href={hrefLangLink.href}
      hrefLang={hrefLangLink.hreflang}
      key={`${hrefLangLink.hreflang}:${hrefLangLink.href}`}
      rel='alternate'
      data-testid='href-lang-link'
    />
  ))
}

HrefLangLinks.displayName = 'HrefLangLinks'
HrefLangLinks.defaultProps = {
  hrefLangLinks: null,
  locales: [],
}
HrefLangLinks.propTypes = {
  currentUrl: PropTypes.string,
  // hrefLangLinks overrides what is generated by the locales
  hrefLangLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      hreflang: PropTypes.string,
    })
  ),
  locales: PropTypes.arrayOf(HREFLANG_LOCALE),
}

export default HrefLangLinks
